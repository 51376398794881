<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$cbglCbxxSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" @handleCommand="handleCommand" :dynamic='true' :isCbxx='true'></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne" :defaultChoose="defaultChoose"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" :isCbxx="true"  @tableColumnClick="tableColumnClick"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>


    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

    <!-- 指令弹窗 -->
    <el-dialog class="dialog-mini user-dialog" :title="instructionTitle" :visible.sync="instructionDialog" width="20%">
      <div>
        <!-- 表底数（当前读数） -->
        <template v-if="optionType == 'input'">
          <!-- 设置底数 -->
          <el-input v-model="optionForm.currentReading" v-if="instructionType == '1'"></el-input>
          <!-- 设置表地址 -->
          <el-input v-model="optionForm.surfaceAddress" v-if="instructionType == '2'"></el-input>
          <!-- 设置上报周期 -->
          <el-input v-model="optionForm.sbzq" v-if="instructionType == '3'"></el-input>
          <!-- 设置阀门状态 -->
          <!-- <el-input v-model="optionForm.fmzt" v-if="instructionType == '5'"></el-input> -->
          <!-- 设置南向地址 -->
          <el-input v-model="optionForm.nxdz" v-if="instructionType == '6'"></el-input>
          <!-- 远程升级 -->
          <el-input v-model="optionForm.ycsj" v-if="instructionType == '7'"></el-input>
          <!-- 电表数据读取 -->
          <el-input v-model="optionForm.dbdqs" v-if="instructionType == '8'"></el-input>
          <!-- 电表跳闸 -->
          <el-input v-model="optionForm.dbtz" v-if="instructionType == '9'"></el-input>
          <!-- 电表合闸 -->
          <el-input v-model="optionForm.dbhz" v-if="instructionType == '10'"></el-input>
        </template>
        <template v-else>
          <!-- 设置上报时间 -->
          <el-time-picker v-model="optionForm.sbsj" placeholder="任意时间点"> </el-time-picker>
        </template>
      </div>
      <div style="text-align:right;" slot="footer">
        <el-button size="small" type="cancel" @click="instructionDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="instructionDialogConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],//选中集合
          queryType: '0',
          queryKey:'UserNumber',
          queryValue:'',
          OtherKey:'',//查询条件
          OtherValue:'',//查询内容
          issueType:'0',//操作范围
          ids:[],//操作范围传值
          timer:'',
          beginTime:'',
          endTime:'',
          repeat:'',
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          UserNumber:'',//用户编号
          SurfaceNum:'',//表编号
          SurfaceAddress:'',//表地址
          CurrentReading:'',//当前读数
        },
        FormList:{//表格列
          column:[
            {columnKey:'CurrentReading',columnDescription:'当前读数',columnType:'num',prop:'CurrentReading',width:'120',placeholder:"当前读数",step:0.0001,},
            {columnKey:'UserNumber',columnDescription:'用户编号',columnType:'text',prop:'UserNumber',width:'120',placeholder:"用户编号",},
            {columnKey:'SurfaceNum',columnDescription:'表编号',columnType:'text',prop:'SurfaceNum',width:'120',placeholder:"表编号",},
            {columnKey:'SurfaceAddress',columnDescription:'表地址',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"表地址",},
          ],
          //校验
          rule:{
            CurrentReading: [{ required: true, message: "当前读数不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'人工抄表',//添加弹窗标题
        instructionTitle:'',//指令弹窗标题
        instructionDialog:false,//指令弹窗控制
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        chooseList:[],//多选数据
        optionData:{},//操作指令数据
        optionForm:{//操作指令弹窗from
          currentReading:'',//表底数（当前读数）
          surfaceAddress:'',//表地址
          sbzq:'',//上报周期
          sbsj:'',//上报时间
          nxdz:'',//南向地址
          ycsj:'',//远程升级
          dbdqs:'',//电表数据读取
          dbtz:'',//电表跳闸
          dbhz:'',//电表合闸
        },
        optionType:'',//操作指令弹窗组件状态
        instructionType:'',//操作指令code，根据code选择不同form赋值
        handleCommandOption:{},//点击操作指令，用于阀门控制取值
        defaultChoose:[],//通过跳转过来的页面默认选中树
      }
    },
    watch: {
      
    },
    computed: {
      // orgsTree() {
      //   return this.$store.state.AllArea;
      // },
    },
    filters: {
      
    },
    created() {
      if(this.$route.query.id){//如果是页面跳转
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(this.$route.query.id)
        this.defaultChoose = []
        this.defaultChoose.push(this.$route.query.id)
      }
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })
      // this.dragControllerDiv();
      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        if(domId == 'artificial'){//人工抄表
          this.artificialMemter()
          return
        }
        if(domId == 'btnExport'){
          this.handleDownExcel()
          return
        }
        if(this.chooseList.length <= 0){
          this.$message.error('请选择一个设备进行操作！');
          return;
        }
        if((this.listQuery.issueType == '0' || this.listQuery.issueType == '1' || this.listQuery.issueType == '2') && this.chooseList.length != 1){
          this.$message.error('请选择一个设备进行操作！');
          return
        }
        if(this.listQuery.issueType == '3' && this.chooseList.length <= 0){
          this.$message.error('请选择区域');
          return
        }

        this.listQuery.ids = []
        if(this.listQuery.issueType == '0'){//选中表
          this.listQuery.ids.push(this.chooseList[0].id)
        }else if(this.listQuery.issueType == '1'){//当前采集器
          this.listQuery.ids.push(this.chooseList[0].collectorId)
        }else if(this.listQuery.issueType == '2'){//当前集中器
          this.listQuery.ids.push(this.chooseList[0].concentratorId)
        }else if(this.listQuery.issueType == '3'){//选中区域
          this.listQuery.ids = this.listQuery.TreeIds
        }else if(this.listQuery.issueType == '4'){//所有区域
          this.listQuery.ids=[]
          this.$store.state.AllAreaGetTreeId.forEach(i => {
            this.listQuery.ids.push(i.id)
          });
        }

        this.optionData={
          issueType:this.listQuery.issueType,//操作范围取值
          ids:this.listQuery.ids,//操作数据id集合，区域或设备
        }
        switch (domId) {
          case 'btnCollect'://采集
            basicDataApi.cbxxInstructionCj(this.optionData).then(response => {
              if(response.code == 200){
                this.$notify({
                  message: response.message,
                  type: 'success',
                  duration: 2000
                });
              }else{
                this.$notify({
                  message: response.message,
                  type: 'warning',
                  duration: 2000
                });
              }
            }).catch(()=>{
              this.$message({
                message: '采集失败,请稍后重试!',
                type: 'warning'
              });
            })
            break
          case 'btnRead'://读取
            basicDataApi.cbxxInstructionDq(this.optionData).then(response => {
              if(response.code == 200){
                this.$notify({
                  message: response.message,
                  type: 'success',
                  duration: 2000
                });
              }else{
                this.$notify({
                  message: response.message,
                  type: 'warning',
                  duration: 2000
                });
              }
            }).catch(()=>{
              this.$message({
                message: '读取失败,请稍后重试!',
                type: 'warning'
              });
            })
            break
          case 'btnOpen'://开阀
            this.instructionType = '5';
            this.handleCommandOption.command = 'kf'
            this.instructionDialogConfirm();//开阀、关阀、阀门摆动直接走接口
            // basicDataApi.cbxxInstructionKf(this.listQuery).then(response => {
            //   if(response.code == 200){
            //     this.$message(response.message)
            //   }else{
            //     this.$notify({
            //       message: response.message,
            //       type: 'warning',
            //       duration: 2000
            //     });
            //   }
            // }).catch(()=>{
            //   this.$message({
            //     message: '开阀失败,请稍后重试!',
            //     type: 'warning'
            //   });
            // })
            break
          case 'btnClose'://关阀
            this.instructionType = '5';
            this.handleCommandOption.command = 'gf'
            this.instructionDialogConfirm();//开阀、关阀、阀门摆动直接走接口
            // basicDataApi.cbxxInstructionGf(this.listQuery).then(response => {
            //   if(response.code == 200){
            //     this.$message(response.message)
            //   }else{
            //     this.$notify({
            //       message: response.message,
            //       type: 'warning',
            //       duration: 2000
            //     });
            //   }
            // }).catch(()=>{
            //   this.$message({
            //     message: '关阀失败,请稍后重试!',
            //     type: 'warning'
            //   });
            // })
            break
          default:
            break
        }
      },
      // 下拉菜单事件
      handleCommand(val){
        // 表底数、表地址修改
        if((val.dtCode == '1' || val.dtCode == '2') && this.chooseList.length != 1){
          this.$message.error('NB集中器下的设备不支持多表读数修改');
          return
        }
        
        // this.listQuery.issueType:   0:选中表,1:当前采集器,2:当前集中器,3:选中区域,4:所有区域
        if((this.listQuery.issueType == '0' || this.listQuery.issueType == '1' || this.listQuery.issueType == '2') && this.chooseList.length != 1){
          this.$message.error('请选择一个设备进行操作！');
          return
        }
        if(this.listQuery.issueType == '3' && this.chooseList.length <= 0){
          this.$message.error('请选择区域');
          return
        }

        this.listQuery.ids = []
        if(this.listQuery.issueType == '0'){//选中表
          this.listQuery.ids.push(this.chooseList[0].id)
        }else if(this.listQuery.issueType == '1'){//当前采集器
          this.listQuery.ids.push(this.chooseList[0].collectorId)
        }else if(this.listQuery.issueType == '2'){//当前集中器
          this.listQuery.ids.push(this.chooseList[0].concentratorId)
        }else if(this.listQuery.issueType == '3'){//选中区域
          this.listQuery.ids = this.listQuery.TreeIds
        }else if(this.listQuery.issueType == '4'){//所有区域
          this.listQuery.ids=[]
          this.$store.state.AllAreaGetTreeId.forEach(i => {
            this.listQuery.ids.push(i.id)
          });
        }
        this.optionData={
          issueType:this.listQuery.issueType,//操作范围取值
          ids:this.listQuery.ids,//操作数据id集合，区域或设备
        }

        if(val.dtCode == '4'){this.optionType = 'time'}else{this.optionType = 'input'}
        this.optionForm = JSON.parse(JSON.stringify(this.chooseList[0]))//赋值后操作不影响原数据
        this.instructionType = val.dtCode
        this.instructionTitle = val.parameter
        this.handleCommandOption = val
        if(val.dtCode == '5'){
          this.instructionDialogConfirm();//开阀、关阀、阀门摆动直接走接口
        }else{
          this.instructionDialog = true;
        }
      },
      // 指令弹窗确认
      instructionDialogConfirm(){
        this.optionData.controlKey = parseInt(this.instructionType);//选中指令key
        if(this.instructionType == '1'){//设置底数
          this.optionData.controlValue = this.optionForm.currentReading;
        }
        if(this.instructionType == '2'){//设置表地址
          this.optionData.controlValue = this.optionForm.surfaceAddress;
        }
        if(this.instructionType == '3'){//设置上报周期
          this.optionData.controlValue = this.optionForm.sbzq;
        }
        if(this.instructionType == '4'){//设置上报时间
          this.optionData.controlValue = this.optionForm.sbsj;
        }
        if(this.instructionType == '5'){//设置阀门状态
          if(this.handleCommandOption.command == 'kf'){//开阀
            this.optionData.controlValue = '2';
          }else if(this.handleCommandOption.command == 'gf'){//关阀
            this.optionData.controlValue = '0';
          }else{//阀门摆动
            this.optionData.controlValue = '1';
          }
        }
        if(this.instructionType == '6'){//设置南向地址
          this.optionData.controlValue = this.optionForm.nxdz;
        }
        if(this.instructionType == '7'){//远程升级
          this.optionData.controlValue = this.optionForm.ycsj;
        }
        if(this.instructionType == '8'){//电表数据读取
          this.optionData.controlValue = this.optionForm.dbdqs;
        }
        if(this.instructionType == '9'){//电表跳闸
          this.optionData.controlValue = this.optionForm.dbtz;
        }
        if(this.instructionType == '10'){//电表合闸
          this.optionData.controlValue = this.optionForm.dbhz;
        }
        
        basicDataApi.cbxxZlxf(this.optionData).then(response => {
          if(response.code == 200){
            this.instructionDialog = false;
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
        
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.cbxxGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        console.log(this.addform)
        if(!this.addform.UserNumber && !this.addform.SurfaceNum && !this.addform.SurfaceAddress){
          this.$notify({
            message: '至少输入用户编号、表编号、表地址中的一个',
            type: 'warning',
            duration: 2000
          });
          return
        }
        basicDataApi.cbxxRgcb(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.addform={
              UserNumber:'',//用户编号
              SurfaceNum:'',//表编号
              SurfaceAddress:'',//表地址
              CurrentReading:'',//当前读数
            }
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.FormDialog = false
        })
      },
      // 导出
      handleDownExcel() {
        this.loading = true
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.cbxxExport(this.listQuery).then(response => {
          if(response.code == 200){
            var url = process.env.VUE_APP_BASE_IMG_URL + '/'+ response.result
            window.location.href = url
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        })
      },
      //人工抄表
      artificialMemter(){
        this.FormDialog = true
      },
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
